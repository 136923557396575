import useAuthStore from "../store/useAuthStore";


export const recoverUser = async (username) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/forgot-password`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'},
            body: JSON.stringify({ username }),
        }
    );
    const responseData = await response.json();
    if (responseData && responseData.success){
        return true;
    }
    return false;
};

export const adminFetchUser = async (username) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/admin/fetch-user`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: useAuthStore.getState().getToken(),
            },
            body: JSON.stringify({ username }),
        }
    );
    const responseData = await response.json();
    if (responseData.link.properties){
        return responseData.link.properties.action_link;
    }
    return null;
};
