/**
 * This is the component for the electrification scenario dropdown in the dashboard.
 * 
 * Parent: Dashboard.js
 */

import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/useAuthStore";
import useCache from "../../store/useCache";
import useProForma from "../../store/useProForma";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const ElectrificationScenarioDropdown = () => {
  const { user } = useAuthStore();
  const {
    controls,
    fleetData,
    fetchAndUpdateFleet,
    yearOverYear,
    phases,
    years,
  } = useProForma();
  const { updateControls } = useCache();
  const [siteOptions, setSiteOptions] = useState([]);
  const [site, setSite] = useState("");
  const [electrificationOptions, setElectrificationOptions] = useState([]);
  const [electrificationScenario, setElectrificationScenario] = useState("");

  useEffect(() => {
    if (fleetData && fleetData[0] && fleetData[0].electrification_scenario) {
      const options = Object.keys(fleetData[0].electrification_scenario);
      setElectrificationOptions(options);
    }
  }, [fleetData]);

  useEffect(() => {
    if (controls.domiciles) {
      setElectrificationScenario(controls["electrification_scenario"]);
    }
  }, [controls, fleetData]);

  const updateControl = async (attribute, value) => {
    if (value === "" || value === null) return;
    try {
      updateControls(attribute, value);
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/controls/${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: useAuthStore.getState().getToken(),
          },
          body: JSON.stringify({ attribute, value }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
    } catch (error) {
      console.error(`Error updating control: ${error.message}`);
    }
  };

  const handleElectrificationScenarioChange = (str) => {
    if (str === "") {
      return;
    }
    const newValue = str;
    setElectrificationScenario(newValue);
    updateControl("electrification_scenario", newValue);
  };
  return (
    <div className="max-w-full">
      <DropdownMenu className="">
      <DropdownMenuTrigger asChild>
        <div className="inline-flex items-center w-full text-xl lg:text-sm">
        <div className="text-[#40423f] font-semibold font-['Inter'] leading-normal w-full truncate overflow-hidden">
            {electrificationScenario}
          </div>
          <img width={20} src="Keyboard arrow down.svg"></img>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuRadioGroup value={electrificationOptions} onValueChange={handleElectrificationScenarioChange}>
          {electrificationOptions?.map((option) => (
            <DropdownMenuRadioItem key={option} value={option}>
              {option}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
    </div>
  );
};

export default ElectrificationScenarioDropdown;
