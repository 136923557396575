import React from 'react';

export const CustomTooltip = ({ active, payload, label, formatter, getBarColor }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div 
      className="p-4 rounded-lg shadow-lg border border-gray-200 z-50"
      style={{ backgroundColor: 'rgba(211, 211, 211, 0.8)' }} // Transparent grey background
    >
      <p className="font-semibold text-gray-900 mb-2">{label}</p>
      {payload.map((entry, index) => {
        const color = (getBarColor && !entry.color) ? getBarColor(entry.value) : entry.color;
        return (
          <div key={index} className="flex items-center space-x-2 mb-1 flex-nowrap">
            <div 
              className="w-3 h-3 rounded-full" 
              style={{ backgroundColor: color }}
            />
            <span className="text-gray-700 truncate">{entry.name}:</span>
            <span className="font-medium">
              {formatter ? formatter(entry.value) : entry.value.toLocaleString()}
            </span>
          </div>
        );
      })}
    </div>
  );
};