/**
 * @fileoverview Fleet Editor outer level component
 */

import React, { useState } from "react";
import ResetButton from "components/ResetButton";
import FleetGrid from "components/fleet-editor/FleetGrid";
import { jsonToCsv } from "utils/jsonToCsv";
import { Button } from "components/ui/button";
import useProForma from "store/useProForma";
import AddDeleteFleet from "components/fleet-editor/AddDeleteFleet";
import useAuthStore from "store/useAuthStore";
import ToggleNav from "components/ToggleNav";
import { getIndivCharger } from "components/fleet-editor/getCharger";

const filterOut = (obj) => {
  obj.forEach((item) => {
    delete item.equipment_id;
    delete item.user_id;
    delete item.id;
  });
  return obj;
};

function FleetEditor() {
  const { fleetData, fetchAndUpdateFleet, cityInfo } = useProForma();

  const filename = "fleet-data.csv";

  /**
   * @function downloadCsv - Downloads the fleet data as a CSV file
   */
  const downloadCsv = () => {
    const data = [...fleetData];
    data.map((item) => {
      delete item["electrification_scenario"];
      return item;
    });
    const customColumnMappings = {
      description: "Description",
      state_incentives: "State or Local Incentives Rebates or Credits",
      "Replacement Year": "Electrification Year",
      "Simplified Domicile": "Domicile",
      "EV Purchase Cost pre-incentive":"EV Purchase Cost (Not Including Incentives)",
      "Existing Vehicle Annual Maintenance":"Existing Vehicle Annual Maintenance Costs",
      "IRA Incentives":"Federal Incentives Rebates or Credits",
      "ghg":"Existing Vehicle GHG Emissions",
      "vehicle_type":"Vehicle Type",
      "charger_assignment":"Charger Assignment"
    };

    data.forEach(item => {
      if (item.charger_assignment) {
        item.charger_assignment = getIndivCharger(cityInfo, item.charger_assignment);
      }
    });
    const csv = jsonToCsv(filterOut(data), customColumnMappings);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState(null);

  // Store gridApi to access selected rows
  const [gridApi, setGridApi] = useState(null);

  /**
   * @function handleDeleteRow - Deletes the selected rows from the grid and the server
   * INCOMPLETE
   */
  const handleDeleteRow = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const remainingRows = rowData.filter((row) => !selectedData.includes(row));
    setRowData(remainingRows);

    // Optionally, send a delete request to your server
    selectedData.forEach(async (row) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROUTE}api/phases/${row.id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: useAuthStore.getState().getToken(),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete data");
        }
        await fetchAndUpdateFleet("phases");
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    });
  };

  return (
    <div className="h-screen">
      <div className="flex lg:flex-col lg:mb-4 justify-between mb-4">
        <div className="flex items-center w-full justify-between p-5">
          <div>
            <div className="flex justify-between gap-2">
              <ToggleNav />
            </div>
            <div className="text-[#40423f] text-[23px] font-medium font-['Roboto'] leading-7">
              Fleet Editor
            </div>
            <div className="text-[#656864] text-[17px] font-medium font-['Inter'] leading-tight">
              Fleet inventory and analysis
            </div>
          </div>
          <div className="bg-white p-4 rounded-full flex gap-2">
            <ResetButton tableName="fleet data" />
            <Button
              variant="outline"
              onClick={downloadCsv}
              className="rounded-full flex gap-2 bg-white"
            >
              <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none flex gap-2 items-center">
                <img src="icons/exportIcon.svg"></img>Export to .csv
              </div>
            </Button>
          </div>
        </div>

        <div>
          {/* <AddDeleteFleet  rowData={rowData} setRowData={setRowData} gridApi={gridApi}/> */}
        </div>
      </div>
      <div className="flex rounded-3xl gap-6 bg-gray2 p-6 h-[calc(100vh-130px)] w-full">
        <FleetGrid
          rowData={rowData}
          setRowData={setRowData}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      </div>
    </div>
  );
}

export default FleetEditor;
