import React, { useState } from "react";
import { recoverUser } from "../api/adminapi";
import { Card, CardHeader, CardContent, CardFooter } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useToast } from "components/hooks/use-toast";
import { Label } from "components/ui/label";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const success = await recoverUser(email);
    // Handle forgot password logic here
    if (success) {
      toast({
        title: "Success",
        description: "An email has been sent to the recovery email",
      });
    } else {
      toast({
        title: "Error",
        description: "User not found",
        variant: "destructive",
      });
    }

    setEmail("");
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <Card className="w-96 shadow-lg">
        <CardHeader>
          <h2 className="text-xl font-bold">Forgot Password</h2>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium">
                Username:
              </label>
              <Input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your username"
                className="mt-2"
              />
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex justify-end">
          <Dialog>
            <DialogTrigger asChild>
              <Button>Submit</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md">
              <DialogHeader>
                <DialogTitle>Are you sure you want to do this?</DialogTitle>
                <DialogDescription>
                  An email will be sent to reset your password.
                </DialogDescription>
              </DialogHeader>
              <div className="flex items-center space-x-2">
                <div className="grid flex-1 gap-2">
                  <Label htmlFor="link" className="sr-only">
                    Link
                  </Label>
                </div>
              </div>
              <DialogFooter className="">
                <DialogClose asChild>
                  <Button onClick={handleSubmit} type="submit">
                    Reset
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ForgotPassword;
