/* global process */
import useAuthStore from 'store/useAuthStore';

const updatePhaseField = async (field, value, id) => {
  const token = useAuthStore.getState().getToken();
  const response = await fetch(
    `${process.env.REACT_APP_API_ROUTE}api/phases/patch`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ [field]: value, id }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to update data");
  }

  return response.json();
};

export { updatePhaseField }; 