/**
 * PhaseGrid Component
 * 
 * A data grid component for managing charging infrastructure phases using ag-Grid.
 * Displays and allows editing of phase data including:
 * - Site locations and years
 * - Port configurations and quantities
 * - Cost breakdowns (equipment, installation, utilities, etc.)
 * - Incentives and funding sources
 * 
 * Features:
 * - Inline cell editing
 * - Dynamic column visibility based on user access
 * - Automatic data persistence
 * - Responsive layout with horizontal scroll shadow
 * - Pagination
 * 
 * @component
 * @param {Object} props
 * @param {Object} props.gridApi - AG Grid API instance (currently unused but maintained for future use)
 * @param {Function} props.setGridApi - Function to store the grid API reference
 * @param {Array} props.rowData - Array of phase data objects to display
 * @param {Function} props.setRowData - Function to update the phase data
 */

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid
import React, { useState, useEffect, useMemo } from "react"; // React State Management
import usePhases from "store/usePhases";
import useAuthStore from "store/useAuthStore";
import useColumnState from "store/useColumnState";
import useProForma from "store/useProForma";
import { fetchMasterAccessData } from "api/masteraccessapi";
import { updatePhaseField } from 'api/phaseApi';
import PropTypes from "prop-types";

/**
 * Formats a number as a currency string with dollar sign and thousands separators
 * Returns "-" for zero values and empty string for null/undefined
 * @param {number} number - The number to format
 * @returns {string} Formatted currency string
 */
const formatAsCurrency = (number) => {
  if (number === null || number === undefined) return "";
  if (number === 0) return "-";
  return `$${Math.floor(number).toLocaleString()}`;
};

/**
 * Checks if a JavaScript object is empty
 * @param {Object} obj - Object to check
 * @returns {boolean} True if object has no own properties
 */
const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const PhaseGrid = ({ gridApi, setGridApi, rowData, setRowData }) => {
  const { addPhase, updatePhase } = usePhases();
  const {
    fetchAndUpdateFleet,
    phases: phaseObj,
    controls,
    cityInfo,
  } = useProForma();
  const { phases } = phaseObj;
  const { user } = useAuthStore();
  const { phaseColumns, setPhaseColumns } = useColumnState();

  const [siteOptions, setSiteOptions] = useState(
    controls.domiciles?.map((option) => option) || []
  );

  /**
   * Initializes the grid and sets up scroll event listener
   * @param {Object} params - Grid API and column API
   */
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.addEventListener("bodyScroll", onBodyScroll);
  };

  /**
   * Fetches master access data when user changes
   * Updates column visibility and header names based on organization
   */
  useEffect(() => {
    async function fetchData() {
      if (user) {
        const username = user.email.split("@")[0];
        const data = await fetchMasterAccessData(username);
        if (!data.error) {
          setColDefs((prevColDefs) =>
            prevColDefs.map((colDef) =>
              colDef.field === "master_access_incentives"
                ? { 
                    ...colDef,
                    hide: !data.incentive_column_title, // Hide if title is empty
                    headerName: `${data.incentive_column_title}`
                  }
                : colDef
            )
          );
        }
      }
    }
    fetchData(user);
  }, [user]);

  useEffect(() => {
    setSiteOptions(controls.domiciles?.map((option) => option) || []);
  }, [controls]);
  useEffect(() => {
    setRowData(phases);
  }, [phases]); //maybe change this

  // Row Data: The data to be displayed.

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "year", editable: true, pinned: true },
    {
      field: "site",
      editable: true,

      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: siteOptions,
      },
      pinned: true,
    },
    {
      field: "port_less_than_10_kw",
      cellStyle: { textAlign: "right" },
      editable: true,
      type: "number",
      valueFormatter: (params) => (params.value === 0 ? "-" : params.value),
    },
    {
      headerName: "Ports 10-20 kW",
      field: "port_10_20_kw",
      cellStyle: { textAlign: "right" },
      editable: true,
      type: "number",
      valueFormatter: (params) => (params.value === 0 ? "-" : params.value),
    },
    {
      headerName: "Ports 40 kW",
      field: "port_25_kw",
      cellStyle: { textAlign: "right" },
      editable: true,
      type: "number",
      valueFormatter: (params) => (params.value === 0 ? "-" : params.value),
    },
    {
      headerName: "Ports 180-200 kW",
      field: "port_180_200_kw",
      cellStyle: { textAlign: "right" },
      editable: true,
      type: "number",
      valueFormatter: (params) => (params.value === 0 ? "-" : params.value),
    },
    {
      headerName: "Loan Amount",
      field: "loan_amount",
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
      editable: true,
    },
    {
      headerName: "Capital Planning Funding",
      field: "capital_planning_funding",
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
      editable: true,
    },
    {
      headerName: "Trenching Costs",
      field: "trenching_costs",
      editable: true,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Upgrade Cost Utility",
      field: "upgrade_cost_utility",
      editable: true,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Upgrade Cost (customer)",
      field: "upgrade_cost_customer",
      type: "currency",
      editable: true,
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Procurement Management Cost",
      field: "procurement_management_cost",
      editable: true,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Public Works Engineering Costs",
      field: "estimated_public_works_engineering_costs",
      editable: true,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
    },
    {
      headerName: "Other Costs",
      field: "other_costs",
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
      editable: true,
    },
    {
      headerName: "Description of Other Costs",
      field: "other_costs_description",
      type: "text",
      cellStyle: { textAlign: "left" },
      editable: true,
    },
    {
      headerName: "Incentives, Rebates, and Credits",
      headerTooltip: "Recorded as cost reduction at point-of-sale",
      field: "incentives",
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
      editable: true,
    },
    {
      headerName: "Master Access Incentives",
      headerTooltip: "Recorded as cost reduction at point-of-sale",
      field: "master_access_incentives",
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: { textAlign: "right" },
      editable: true,
      hide: true,
    },
    
    {
      headerName: "Charger Equipment Cost",
      field: "cost",
      editable: false,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: {
        textAlign: "right",
        fontWeight: "bold",
        color: "black",
        border: "none",
        background: "#F2F2F2",
      },
    },
    {
      headerName: "Installation Cost",
      field: "installCost",
      editable: false,
      type: "currency",
      valueFormatter: (params) => formatAsCurrency(params.value),
      cellStyle: {
        textAlign: "right",
        fontWeight: "bold",
        color: "black",
        border: "none",
        background: "#F2F2F2",
      },
    },
  ]);

  /**
   * Updates column definitions when site options change
   */
  useEffect(() => {
    if (siteOptions.length > 0) {
      setColDefs((prevColDefs) =>
        prevColDefs.map((colDef) =>
          colDef.field === "site"
            ? {
                ...colDef,
                cellEditorParams: {
                  ...colDef.cellEditorParams,
                  values: siteOptions,
                },
              }
            : colDef
        )
      );
    }
  }, [siteOptions]);

  /**
   * Updates port column headers based on city-specific range configurations
   */
  useEffect(() => {
    setColDefs((prevColDefs) =>
      prevColDefs.map((colDef) => {
        if (colDef.field === "port_less_than_10_kw") {
          return {
            ...colDef,
            headerName: cityInfo.range_1
              ? "Ports " + cityInfo.range_1
              : "Ports <10 kW",
          };
        } else if (colDef.field === "port_10_20_kw") {
          return {
            ...colDef,
            headerName: cityInfo.range_2
              ? "Ports " + cityInfo.range_2
              : "Ports 10-20 kW",
          };
        } else if (colDef.field === "port_25_kw") {
          return {
            ...colDef,
            headerName: cityInfo.range_3
              ? "Ports " + cityInfo.range_3
              : "Ports 40 kW",
          };
        } else if (colDef.field === "port_180_200_kw") {
          return {
            ...colDef,
            headerName: cityInfo.range_4
              ? "Ports " + cityInfo.range_4
              : "Ports 180-200 kW",
          };
        } else {
          return colDef;
        }
      })
    );
  }, [cityInfo]);

  /**
   * Handles cell value changes
   * Validates input, updates the backend, and refreshes related data
   * @param {Object} event - Cell value change event
   */
  const handleCellValueChanged = async (event) => {
    const updatedData = event.data;
    const field = event.colDef.field;
    let value = event.newValue;
    if (field === "other_costs_description" && value === null) {
      event.node.setDataValue(field, "");
    } else if (value === null || value === undefined) {
      value = 0;
      event.node.setDataValue(field, value);
    }

    try {
      const result = await updatePhaseField(field, value, updatedData.id);
      await updatePhase(result[0]);
      await fetchAndUpdateFleet("phases");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitCellContents",
      skipHeader: false,
    };
  }, []);

  const onGridPreDestroyed = (event) => {
    setPhaseColumns(event.state);
  };
  const [shadow, setShadow] = useState(true);
  /**
   * Handles horizontal scrolling and updates shadow visibility
   * @param {Object} event - Scroll event
   */
  const onBodyScroll = (event) => {
    const horizontalScrollPosition = event.api.getHorizontalPixelRange();
    // const scrollWidth = event.api.gridPanel.getBodyClientRect().width;
    const size = event.api.getColumnState();
    // const maxScrollLeft = horizontalScrollPosition.right - scrollWidth;
    const totalWidth = size.reduce((total, obj) => {
      if (!obj.pinned && !obj.hide) {
        return total + obj.width;
      }
      return total;
    }, 0);
    if (totalWidth - horizontalScrollPosition.right < 1) {
      setShadow(false);
    } else {
      setShadow(true);
    }
  };

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-material w-full h-full" // applying the grid theme
    >
      <div className="relative">
        <AgGridReact
          initialState={phaseColumns}
          autoSizeStrategy={isEmpty(phaseColumns) ? autoSizeStrategy : {}}
          domLayout="autoHeight"
          stopEditingWhenCellsLoseFocus={true}
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection="single"
          onCellValueChanged={handleCellValueChanged}
          onGridReady={onGridReady}
          suppressColumnVirtualisation={true}
          maintainColumnOrder={true}
          onGridPreDestroyed={onGridPreDestroyed}
          tooltipShowDelay={0}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 30]}
          // paginationAutoPageSize={true}
        />
        {shadow && (
          <div className="h-full absolute top-0 right-0 bottom-0 w-5 bg-gradient-to-r from-transparent to-black/10 pointer-events-none z-20 rounded-lg"></div>
        )}
      </div>
    </div>
  );
};

PhaseGrid.propTypes = {
  gridApi: PropTypes.object,
  setGridApi: PropTypes.func.isRequired,
  rowData: PropTypes.array,
  setRowData: PropTypes.func.isRequired,
};

export default PhaseGrid;
