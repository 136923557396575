import { useCallback } from 'react';
import useProForma from 'store/useProForma'; // Adjust the path to your hook

const useHandleEvalYearUpdate = () => {
  const { updateEvalYear } = useProForma();

  const handleUpdateEvalYear = useCallback((e) => {
    if (e && e.activeLabel !== undefined) {
      const xValue = parseInt(e.activeLabel, 10);
      updateEvalYear(xValue);
    }
  }, [updateEvalYear]);

  return handleUpdateEvalYear;
};

export default useHandleEvalYearUpdate;