import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({ to, icon: Icon, label, isOpen }) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <li
          className={`
            flex items-center rounded-xl py-3 text-sm font-medium mb-2
            ${isOpen ? 'px-4' : 'h-11 w-11'}
            ${isActive 
              ? "bg-black text-white" 
              : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
            }
          `}
        >
          <Icon 
            className={isOpen ? "mr-2" : "m-auto"} 
            width={label === "Dashboard" ? "20" : "18"} 
            height={label === "Dashboard" ? "20" : "18"} 
          />
          {isOpen && label}
        </li>
      )}
    </NavLink>
  );
};

export default NavItem; 