/**
 * @fileoverview Set Defaults admin page outer level component
 */

import React, { useState } from "react";
import { Upload } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import useAuthStore from "../store/useAuthStore";
import { useDropzone } from "react-dropzone";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import useProForma from "../store/useProForma";
import { useToast } from "components/hooks/use-toast";

function AdminSetDefaults() {
  const { user } = useAuthStore();
  const [csvFile, setCsvFile] = useState(null);
  const [message, setMessage] = useState("");
  const { fetchAndUpdateFleet } = useProForma();
  const { toast } = useToast();

  const onDrop = (acceptedFiles) => {
    setCsvFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const uploadCSVFile = async () => {
    if (!user) return;

    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("userId", user.id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}reupload/`,
        {
          method: "POST",
          headers: {
            Authorization: useAuthStore.getState().getToken(),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const res = await response.json();
        const errorMessage = `CSV format is incorrect.\nServer message: ${res.error}`;
        throw new Error(errorMessage);
      }
      const result = await response.json();
      setMessage(`${result.message}`);
      fetchAndUpdateFleet();
      toast({
        title: "Success",
        description: "Fleet data replaced successfully",
      });
    } catch (error) {
      setMessage(`Error uploading data: ${error.message}`);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const saveDefault = async (tableName) => {
    if (!user) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/save-default?userId=${user.id}&tableName=${tableName}`,
        {
          method: "GET",
          headers: {
            Authorization: useAuthStore.getState().getToken(),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save defaults");
      }

      await response.json();
      toast({
        title: "Success",
        description: `${tableName} defaults saved`,
      });
    } catch (error) {
      console.error("Error saving defaults:", error);
      toast({
        title: "Error saving defaults",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  if (!user) {
    return <div>Log in with a user!</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <Card className="max-w-[800px] min-w-[450px] mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-primary">
            Set Defaults
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <Tabs defaultValue="fleet" className="w-full">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="fleet">Fleet</TabsTrigger>
              <TabsTrigger value="phases">Phases</TabsTrigger>
              <TabsTrigger value="advanced">Advanced Controls</TabsTrigger>
            </TabsList>
            <TabsContent value="fleet" className="space-y-4">
              <div className="grid gap-4">
                <Button 
                  className="w-full"
                  onClick={() => saveDefault("fleet data")}
                >
                  Save Default Fleet
                </Button>
                <Card className="border-2 border-dashed" {...getRootProps()}>
                  <CardContent className="flex flex-col items-center justify-center py-8 text-center">
                    <Input {...getInputProps()} />
                    <Upload className="h-8 w-8 mb-2 text-muted-foreground" />
                    {csvFile ? (
                      <p className="text-sm text-muted-foreground mb-1">
                        {csvFile.name}
                      </p>
                    ) : (
                      <>
                        <p className="text-sm text-muted-foreground mb-1">
                          Drag & Drop Vehicle CSV file here
                        </p>
                        <p className="text-xs text-muted-foreground">
                          or click to select a file
                        </p>
                      </>
                    )}
                    {message && (
                      <p className="text-sm text-red-400 mt-2">{message}</p>
                    )}
                  </CardContent>
                </Card>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="secondary" className="w-full">
                      Replace Fleet
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                      <DialogTitle>Are you sure you want to do this?</DialogTitle>
                      <DialogDescription>
                        Uploading a new CSV file will replace the current fleet data.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <DialogClose asChild>
                        <Button onClick={uploadCSVFile} type="submit">
                          Confirm
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </TabsContent>
            <TabsContent value="phases" className="space-y-4">
              <Button 
                className="w-full"
                onClick={() => saveDefault("phases")}
              >
                Save Default Phases
              </Button>
            </TabsContent>
            <TabsContent value="advanced" className="space-y-4">
              <Button 
                className="w-full"
                onClick={() => saveDefault("advanced controls")}
              >
                Save Default Advanced Controls
              </Button>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}

export default AdminSetDefaults;
