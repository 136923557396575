/**
 * Functions for converting JSON to CSV
 */
const defaultColumnMappings = {
  // Add more column mappings as needed
};
export const jsonToCsv = (jsonData, customColumnMappings = {}) => {
  if (!jsonData || jsonData.length === 0) return '';

  // Merge default mappings with custom mappings
  const columnMappings = { ...defaultColumnMappings, ...customColumnMappings };

  // Get all unique keys from the JSON data
  const keys = Array.from(new Set(jsonData.flatMap(obj => Object.keys(obj))));

  // Create header row with mapped column names
  const headerRow = keys
    .map(key => columnMappings[key] || key)
    .join(',');

  // Create data rows
  const dataRows = jsonData.map(obj =>
    keys.map(key => {
      const value = obj[key];
      // Handle special cases (null, undefined, strings with commas)
      if (value === null || value === undefined) return '';
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`;
      }
      return value;
    }).join(',')
  );

  // Combine header and data rows
  return [headerRow, ...dataRows].join('\n');
};