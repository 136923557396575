import React from 'react';
import { Button } from "../ui/button";

const ContactPromoCard = () => {
  return (
    <div
      style={{ backgroundImage: "url(/treeimg.png)" }}
      className="h-[200px] w-[200px] rounded-2xl gap-4 flex flex-col items-start justify-center px-5"
    >
      <div className="text-white text-[17.35px] font-sm font-['Inter']">
        Optony is working for a greener tomorrow
      </div>
      <a href="mailto:Energy@OptonyUSA.com">
        <Button className="h-10 px-4 flex gap-2" variant="outline">
          <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
            Contact us
          </div>
          <img src="icons/sideArrow.svg" alt="arrow" />
        </Button>
      </a>
    </div>
  );
};

export default ContactPromoCard; 