import useAuthStore from "../store/useAuthStore";

/**
 * @function createControls - function to add control to database
 * 
 * @param {*} defaultControlsData object containing control data to update
 * @returns api response
 */
export const createControls = async (defaultControlsData) => {
  return await fetch(`${process.env.REACT_APP_API_ROUTE}api/controls`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: useAuthStore.getState().getToken(),
    },
    body: JSON.stringify(defaultControlsData),
  });
};

export const updateControls = async (userId, attribute, value) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROUTE}api/controls/${userId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: useAuthStore.getState().getToken(),
        },
        body: JSON.stringify({ attribute, value }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update controls");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating controls:", error);
    throw error;
  }
};
