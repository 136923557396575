/**
 * This component contains the electrification timeline graph (Zero Emission Vehicles graph) for the dashboard.
 */

import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  BarChart,
  Bar,
  ReferenceLine,
} from "recharts";
import useProForma from "store/useProForma";
import { axisStyle, labelStyle } from "./styles";
import useGraphState from "store/useGraphState";
import { PRIMARY_COLOR, SECONDARY_GRAPH_COLOR } from "colors";
import { CustomTooltip } from "components/dashboard/graphs/CustomTooltip";
import useHandleEvalYearUpdate from 'components/hooks/useHandleUpdateEvalYear.js';



const TimelineGraph = () => {
  const { yearHovered } = useGraphState();
  const handleClick = useHandleEvalYearUpdate();
  const { proFormaCalcs, years, controls = {}, fleetData = [] } = useProForma();
  const { vehicleCounts: vehicles, totalVehicles } = proFormaCalcs;
  const [vehiclesElectrified, setVehiclesElectrified] = useState("");
  const [showAllSites, setShowAllSites] = useState(true);

  useEffect(() => {
    if (controls.domiciles){
      setShowAllSites(controls.domiciles.length > 1);
    }
  }, [controls]);

  useEffect(() => {
    if (vehicles) {
      setVehiclesElectrified(vehicles[END_YEAR]);
    }
  }, [vehicles]);

  const { YEARS = [], END_YEAR, EVAL_YEAR } = years;
  // Initialize counters for each year
  const vehicleCounts = YEARS.reduce((acc, year) => {
    acc[year] = 0;
    return acc;
  }, {});

  const allSitesVehicleCounts = YEARS.reduce((acc, year) => {
    acc[year] = 0;
    return acc;
  }, {});

  const maxCounter = YEARS.reduce((acc, year) => {
    acc[year] = 0;
    return acc;
  }, {});

  let maxVehicleCount = 0;

  fleetData.forEach((vehicle) => {
    const replacementYear = vehicle["Replacement Year"];
    if (replacementYear in maxCounter) {
      maxCounter[replacementYear]++;
    }
    if (maxCounter[replacementYear] > maxVehicleCount) {
      maxVehicleCount = maxCounter[replacementYear];
    }
  });
  maxVehicleCount += 2;

  // Count vehicles for each year based on the "Replacement Year"
  fleetData.forEach((vehicle) => {
    const replacementYear = vehicle["Replacement Year"];
    const site = vehicle["Simplified Domicile"];
    if (
      replacementYear in vehicleCounts &&
      (site === controls.site || controls.site === "All Sites")
    ) {
      vehicleCounts[replacementYear]++;
    }
  });

  fleetData.forEach((vehicle) => {
    const replacementYear = vehicle["Replacement Year"];
    const site = vehicle["Simplified Domicile"];
    if (replacementYear in allSitesVehicleCounts) {
      allSitesVehicleCounts[replacementYear]++;
    }
  });
  const data = Object.entries(vehicleCounts).map(([year, count]) => ({
    year: Number(year),
    vehicleCount: count,
  }));

  const data2 = Object.entries(allSitesVehicleCounts).map(([year, count]) => ({
    year: Number(year),
    vehicleCount: count,
  }));
  // Map the data into arrays of objects
  const siteData = Object.entries(vehicleCounts).map(([year, count]) => ({
    year: Number(year),
    siteCount: count,
  }));

  const allSiteData = Object.entries(allSitesVehicleCounts).map(
    ([year, count]) => ({
      year: Number(year),
      allSiteCount: count,
    })
  );

  // Combine the two arrays by the year key
  const combinedData = siteData.map((item) => {
    const match = allSiteData.find((d) => d.year === item.year);
    return match ? { ...item, allSiteCount: match.allSiteCount } : item;
  });

  return (
    <div className="bg-white flex rounded-2xl p-4 h-[280px] lg:h-[350px] border flex-col">
      <div className="w-full flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal whitespace-nowrap">
        <img src="icons/zevicon.svg" width="20"></img>Zero Emission Vehicles
        (ZEV)
      </div>
      <div className="flex flex-grow lg:flex-col lg:gap-4">
        <div className="flex-none flex flex-col justify-start gap-4 w-[200px] lg:flex-row lg:w-full lg:justify-around">
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {vehicleCounts[EVAL_YEAR]}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                Vehicles
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Vehicle Replacements, {EVAL_YEAR}
            </div>
          </div>
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {vehiclesElectrified}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                /{totalVehicles}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Vehicles electrified
            </div>
          </div>
        </div>

        <div className="flex-grow relative flex items-end">
          <ResponsiveContainer height={200}>
            <BarChart onClick={handleClick} data={combinedData} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="year"
                stroke="#7E817D"
                style={axisStyle}
                axisLine={false}
              />
              <YAxis
                stroke="#7E817D"
                style={axisStyle}
                type="number"
                tickFormatter={(value) => Math.round(value)}
                tickCount={5}
                domain={[0, Math.ceil(maxVehicleCount / 4) * 4]}
                axisLine={false}
              >
                <Label
                  value="Count of ZEV Replacements"
                  angle={-90}
                  position="left"
                  offset={-15}
                  style={labelStyle}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip />} formatter={(value) => Math.round(value)} />
              <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
              <ReferenceLine
                x={yearHovered ?? EVAL_YEAR}
                stroke={PRIMARY_COLOR}
              />
              {controls.site !== "All Sites" && (
                <Bar
                  radius={[5, 5, 5, 5]}
                  dataKey="siteCount"
                  fill={SECONDARY_GRAPH_COLOR}
                  name={controls && `${controls.site} Vehicles`}
                />
              )}
              {(controls.site === "All Sites" || showAllSites) && (
                <Bar
                  radius={[5, 5, 5, 5]}
                  dataKey="allSiteCount"
                  fill={PRIMARY_COLOR}
                  name="All Sites Vehicles"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TimelineGraph;
