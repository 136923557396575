import useAuthStore from "../store/useAuthStore";

export const postLoginTimestamp = async (username,user_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ROUTE}api/login-timestamp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: useAuthStore.getState().getToken(),
            },
            body: JSON.stringify({ username, user_id }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
}