/**
 * @fileoverview Set city info admin page outer level component
 * 
 * Components: CityInfoGrid, ChargerInfoForm
 */

import React from "react";
import CityInfoGrid from "components/admin-page/CityInfoGrid";
import ChargerInfoForm from "components/admin-page/ChargerInfoForm";
import useAuthStore from "store/useAuthStore";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";

const AdminSetInfo = () => {
  const { user } = useAuthStore(); 
  if (!user) {
    return <div>Log in with a user!</div>;
  }
  
  return (
    <div className="container mx-auto py-6">
      <Card className="max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-primary">
            Set City Information
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="city" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="city">City Information</TabsTrigger>
              <TabsTrigger value="charger">Charger Information</TabsTrigger>
            </TabsList>
            <TabsContent value="city">
              <CityInfoGrid />
            </TabsContent>
            <TabsContent value="charger">
              <ChargerInfoForm />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminSetInfo;
