import React from 'react';
import { Button } from "../ui/button";
import PropTypes from 'prop-types';

const LearnMorePromoCard = ({ imageUrl, contactLink }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
      }}
      className="h-[200px] w-[200px] rounded-2xl gap-4 flex flex-col items-start justify-end px-5 mb-4"
    >
      <a href={contactLink} target="_blank" rel="noopener noreferrer">
        <Button className="h-10 px-4 flex gap-2 mb-8" variant="outline">
          <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
            Learn More
          </div>
          <img src="icons/sideArrow.svg" alt="arrow" />
        </Button>
      </a>
    </div>
  );
};

LearnMorePromoCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  contactLink: PropTypes.string.isRequired
};

export default LearnMorePromoCard; 